export default [
	{
		path: '',
		name: 'index',
		meta: { guest: true },
		component: () => import('@/views/main/index')
	},
	{
		path: 'about',
		name: 'about',
		meta: { guest: true },
		component: () => import('@/views/main/about')
	},
	{
		path: 'contacts',
		name: 'contacts',
		meta: { guest: true },
		component: () => import('@/views/main/contacts')
	},
	{
		path: 'faq',
		name: 'faq',
		meta: { guest: true },
		component: () => import('@/views/main/faq')
	}
]
