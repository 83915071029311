<template>
	<div id="app">
		<app-header v-if="hideHeader && $route.meta.guest && !access_token" />

		<template v-if='tripName'>
			<a-itinerary-header :name_tour='tripName' />
		</template>
		<template v-else>
			<a-header v-if='hideHeader && $route.meta.requiresAuth && access_token' />
		</template>

		<router-view class='main' />

		<app-footer v-if="hideFooter && $route.meta.guest && !access_token" :isIndexPage='isIndexPage' />

		<v-snackbar />
        <back-to-top right="15px" bottom="15px">
            <img :src="require('@/assets/images/up-arrow.png')" alt="">
        </back-to-top>
	</div>
</template>

<script>
	import AppHeader from "@/components/main/AppHeader";
	import AHeader from "@/components/account/AHeader";
	import AItineraryHeader from '@/views/account/itinerary/header';
	import AppFooter from "@/components/main/AppFooter";
	import { user, course, getSuitcase } from '@/services/request';
	import { Trans } from "@/plugins/Translation";
    import BackToTop from 'vue-backtotop';

	export default {
		name: "app",
		computed: {
			isIndexPage() {
				const routeName = ["index"];
				return this.findRouteName(routeName);
			},
			hideHeader() {
				const routeName = ["sign-in", "password-recovery", "itinerary"];
				return this.findRouteName(routeName);
			},
			hideFooter() {
				const routeName = ["sign-in", "sign-up", "password-recovery"];
				return this.findRouteName(routeName);
			},
			access_token() {
				return this.$store.state.auth.user;
			},
			tripName() {
				return this.$route.query?.tripName ?? null;
			}
		},
		components: {
			AppHeader,
			AHeader,
			AItineraryHeader,
            BackToTop,
			AppFooter
		},
		beforeCreate() {
			if(!window.localStorage.getItem('access_token')) return;

			user().then(res => {
				this.$store.commit("auth/user", res.data);
				this.$store.commit('currency', res.data.currency);

                if((this.$route.params.lang !== undefined) && (this.$route.params.lang !== res.data.language)) {
					Trans.changeLanguage(res.data.language).then(() => {
						document.title = this.$t(`routes.${this.$route.name}`) + ' - ' + process.env.VUE_APP_TITLE;
					});
				}

				course().then(res => this.$store.commit("exchange_rates", res.data.filter(el => el.code !== "USA")));
				getSuitcase().then(res => this.$store.commit('suitcase_total', res.meta.total));
			});
		},
		methods: {
			findRouteName(items) {
				return !items.some(name => name == this.$route.name);
			}
		},
		watch: {
			"$route.name"() {
				document.title = this.$t(`routes.${this.$route.name}`) + ' | ' + this.$t(`seo.title`);
			}
		}
	};
</script>
