<template>
	<div class='a-header a-itinerary-header' :class="{'topFixed': topFixed, 'isStatic': !isStatic}">
		<div class="app-container app-container-fluid a-itinerary-header__container">
			<div class='row flex-nowrap'>
				<div class="col pl-0">
					<div class='a-itinerary-header__wrap'>
						<div class="a-itinerary-header__logo">
							<router-link
								:to="$i18nRoute({ name: 'index' })"
								class="a-itinerary-header__logo--link"
								v-html="require('!html-loader!@/assets/logo-color.svg')"
							/>
						</div>
						<div class='a-itinerary-header__content'>
							<div class='a-itinerary-header__content-wrap'>
								<p class='a-itinerary-header-title'>{{name_tour ? name_tour : $t("account.itinerary.content.name_tour")}}</p>
								<p class='a-itinerary-header-text'>{{$t("account.itinerary.tour_id")}}: {{$route.query.orderId ? $route.query.orderId : $route.params.orderId}}</p>
							</div>
						</div>
					</div>
				</div>
				<div class="col-auto">
					<div class='a-itinerary-header-action'>
						<p class='a-itinerary-header-status'>{{$t("account.itinerary.saved")}}</p>
						<div class="a-itinerary-header-divider" />
						<span class='a-itinerary-header-button' @click='dialog = true'>
							<v-icon icon='delete' size='17' />
						</span>
					</div>
				</div>
			</div>
		</div>
		<a-dialog-delete
			v-model='dialog'
			:title='$t("account.messages.are_you_sure_you_want_to_delete_this_tour")'
			@close-dialog="dialog = false"
			@action-dialog='deletedOrder'
			:loading='loading_btn'
		/>
	</div>
</template>

<script>
	import ADialogDelete from '@/components/account/dialogs/delete';
	import { deletedOrder } from '@/services/request';

	export default {
		data: () => ({
			topFixed: false,
			dialog: false,
			loading_btn: false
		}),
		components: {
			ADialogDelete
		},
		props: ['name_tour'],
		computed: {
			isStatic() {
				return !this.$route.meta.isStatic;
			}
		},
		created() {
			this.container = document.documentElement;
			this.topFixed = this.container.scrollTop >= 1

			window.addEventListener("scroll", () => {
				let self = this;
				self.topFixed = self.container.scrollTop >= 1;
			}, false );
		},
		methods: {
			deletedOrder() {
				this.loading_btn = true;

				deletedOrder(this.$route.params.orderId)
					.then(() => {
						this.$store.commit('notification/successMessage', {title: this.$t('account.messages.success.tour_successfully_deleted')});
						this.dialog = false;
						this.$router.push({ name: "trips", params: {lang: this.$route.params.lang} })
					})
					.finally(() => this.loading_btn = false)
			}
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-itinerary-header {
		&:not(.isStatic) {
			& + .main {
				padding-top: 60px;
				@include minw( $grid-breakpoints-sm ) {
					padding-top: 75px;
				}
			}
		}
		&__container {
			height: 60px;
			display: grid;
			@include minw( $grid-breakpoints-sm ) {
				height: 75px;
			}
		}
		&__wrap {
			display: flex;
			height: 100%;
		}
		&__logo {
			display: flex;
			align-items: center;
			padding-left: 10px;
			padding-right: 10px;
			border-right: 1px solid rgba($black, .1);
			@include minw( $grid-breakpoints-xs ) {
				padding-left: 25px;
				padding-right: 25px;
			}
			&--link {
				width: 30px;
				height: 30px;
				display: block;
				@include minw( $grid-breakpoints-sm ) {
					width: 45px;
					height: 45px;
				}
			}
		}
		&__content {
			display: flex;
			align-items: center;
			height: 100%;
			padding-left: 10px;
			@include minw( $grid-breakpoints-xs ) {
				padding-left: 25px;
			}
			&-wrap {
				display: grid;
			}
		}
		&-title,
		&-text {
			font-size: 13px;
			font-weight: 700;
			@include minw( $grid-breakpoints-xs ) {
				font-size: 16px;
			}
		}
		&-title {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
		&-text {
			color: rgba($cyprus, .6);
			@include maxw( $grid-breakpoints-xs - 1 ) {
				font-size: 10px;
			}
		}
		&-action {
			display: flex;
			align-items: center;
			height: 100%;
		}
		&-status {
			font-size: 10px;
			font-weight: 400;
			color: rgba($black, .5);
			@include minw( $grid-breakpoints-xs ) {
				font-size: 16px;
			}
		}
		&-divider {
			width: 1px;
			height: 20px;
			background-color: rgba($black, 0.15);
			margin-left: 10px;
			margin-right: 5px;
			@include minw( $grid-breakpoints-sm ) {
				margin-left: 25px;
				margin-right: 15px;
			}
		}
		&-button {
			padding: 5px;
			color: rgba($cyprus, .4);
			cursor: pointer;
			transition: color $transition;
			@include minw( $grid-breakpoints-sm ) {
				padding: 10px;
			}
			&:hover {
				color: $green-persian;
			}
		}
	}
</style>
