<template>
    <div class='a-loading' v-if="loading">
        <v-icon icon='loading' :size='size' />
    </div>
</template>

<script>
	export default {
		props: {
            loading: {
				type: Boolean,
				default: false
			},
            size: {
				type: Number,
				default: 14
			}
		}
    }
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

    .a-loading {
        height: 20px;
        display: grid;
        align-items: center;
        color: $green-persian;
        .app-icon {
            animation: rotating .6s linear infinite;
            svg {
                fill: none;
                path {
                    stroke: currentColor;
                    stroke-width: 2px;
                }
                circle {
                    stroke: currentColor;
                    stroke-width: 2px;
                    stroke-opacity: .25;
                }
            }
        }
    }
</style>
