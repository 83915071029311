<template>
	<a-dropdown class='a-select-search' :class='{"a-select-outline": outline, "a-select-small": small}' class-dropdown='a-select__dropdown' :invalid='errors' >
			<template v-slot:label v-if="label">
				<label  class="app-label">
					{{ label }}
				</label>
			</template>
			<template v-slot:field="{ openSelect, closeSelect }">
			<div class="a-select__prepend-inner" v-if='prependIcon' >
				<v-icon :icon="prependIcon" :size="prependIconSize" />
			</div>
			<div class="a-select-search__field">
				<input
					class='a-select-search__input'
					:class='{"withIcon": prependIcon}'
					:value='value'
					@focus="$emit('openSelect')"
					@keyup="openSelect"
					@input="$emit('input', $event.target.value)"
					@blur="$emit('blur', $event.target.value)"
					@keydown.enter.tab='closeSelect'
					type="text"
					:placeholder="placeholder"
					:readonly='readonly'
				>
			</div>
			<div class="a-select__append-inner a-select__append-inner--action ml-auto">
				<v-icon icon="loading" size="14" class='app-icon-loading' v-if='loading' />
				<span @click.stop="$emit('input-clear')" class='a-select__append-inner--action-clear' v-if='value && value.length'>
					<v-icon icon="cross" size="12" />
				</span>
				<span v-else>
					<template v-if='appendIcon'>
						<v-icon :icon="appendIcon" size="14" />
					</template>
				</span>
				<slot name='count' />
			</div>
		</template>
		<template v-slot:dropdown="{ closeSelect }">
			<slot name='dropdown' :closeSelect='closeSelect' />
		</template>
	</a-dropdown>
</template>

<script>
	import { i18n } from '@/plugins/i18n'

	export default {
		props: {
			value: String,
				label: String,
				placeholder: {
				type: String,
				default: i18n.t("account.booking.content.choose")
			},
			"prepend-icon": {
				type: [String, Boolean],
				default: false
			},
			"prepend-icon-size": {
				type: Number,
				default: 22
			},
			"append-icon": {
				type: [String, Boolean],
				default: false
			},
			outline: {
				type: Boolean,
				default: false
			},
			loading: Boolean,
			errors: [Boolean, Array],
			small: {
				type: Boolean,
				default: false
			},
			readonly: {
				type: Boolean,
				default: false
			}
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";

	.a-select-search {
		cursor: text;
		&__field {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
		}
		&__input {
			font-size: 16px;
			font-weight: 400;
			line-height: 23px;
			max-width: 100%;
			min-width: 0;
			width: 100%;
			min-height: 100%;
			box-sizing: border-box;
			color: $black;
			padding-left: 15px;
			padding-right: 31px;
			&.withIcon {
				padding-left: 52px;
			}
			&::placeholder {
				opacity: 1;
				color: rgba($black, .4);
			}
			&:focus::placeholder {
				opacity: 0;
				transition: opacity $transition;
			}
		}
		&-tourist {
			.a-dropdown__slot {
				min-height: 50px;
			}
		}
	}
</style>
