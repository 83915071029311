<template>
	<header class="header" data-scroll-lock-fill-gap :class="{ 'isFixed': isFixed, 'topFixed': topFixed, 'mobile_menu': mobile_menu }">
		<div class="app-container app-container-fluid header__container">
			<div class="row align-items-center flex-nowrap">
				<div class="col">
					<div class="header__logo">
						<router-link
							:to="$i18nRoute({ name: 'index' })"
							class="header__logo--link"
							v-html="require('!html-loader!@/assets/logo.svg')"
						/>
					</div>
				</div>
				<div class="col-auto">
					<div class="header__content">
						<app-nav :items="items" />
						<app-locales-select />
						<div class="header__content--divider" />

						<div class="navigation" @click='mobile_menu = !mobile_menu'>
							<span class="navigation-button" :class="{'open': mobile_menu}"></span>
						</div>
						<buttons-authorization />
					</div>
				</div>
			</div>
		</div>

		<transition name="header__mobile__open">
			<div class='header__mobile' v-if='mobile_menu'>
				<div class='header__mobile__container'>
					<div class='header__mobile__content'>
						<app-nav :items="items" />
						<app-locales-select />
						<buttons-authorization />
					</div>
				</div>
			</div>
		</transition>

	</header>
</template>

<script>
	import AppNav from "@/components/main/AppNav.vue";
	import AppLocalesSelect from "@/components/main/AppLocalesSelect.vue";
	import ButtonsAuthorization from "@/components/main/AppButtonsAuthorization.vue";
	import { disablePageScroll, enablePageScroll } from 'scroll-lock';

	export default {
		data: () => ({
			container: null,
			topFixed: false,
			mobile_menu: false
		}),
		computed: {
			isFixed() {
				const routeName = ["index", "about"];
				return routeName.some(name => name == this.$route.name);
			},
			items() {
				return [
					{ name: this.$t('routes.index'), link: "index" },
					{ name: this.$t('routes.about'), link: "about" },
					{ name: this.$t('routes.contacts'), link: "contacts" }
				]
			}
		},
		components: {
			AppNav,
			AppLocalesSelect,
			ButtonsAuthorization
		},
		created() {
			this.container = document.documentElement;
			this.topFixed = this.container.scrollTop >= 1

			window.addEventListener("scroll", () => {
				let self = this;
				self.topFixed = self.container.scrollTop >= 1;
			}, false );
		},
		watch: {
			"$route.name"() {
				this.mobile_menu = false;
			},
			mobile_menu() {
				setTimeout(() => {
					const hideEl = document.querySelector('.header__mobile__container')
					this.mobile_menu ? disablePageScroll(hideEl) : enablePageScroll(hideEl)
				}, 150)
			}
		},
		beforeDestroy() {
			enablePageScroll()
		}
	};
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.header {
		background-color: $green;
		position: fixed;
		top: 0;
		right: 0;
		left: 0;
		z-index: 10;
		transition: background-color $transition;
		& + .main {
			padding-top: 70px;
			@include minw( $grid-breakpoints-sm ) {
				padding-top: 105px;
			}
		}
		&.isFixed {
			& + .main {
				padding-top: 0;
			}
			&:not(.topFixed) {
				background-color: transparent;
			}
		}
		&.topFixed {
			@include minw( $grid-breakpoints-sm ) {
				.header {
					&__container {
						height: 77px;
					}
					&__logo--link {
						width: 63px;
						height: 63px;
					}
				}
				.app-btn--small {
					height: 47px;
				}
			}
		}
		&__container {
			height: 70px;
			display: grid;
			@include minw( $grid-breakpoints-sm ) {
				height: 105px;
			}
		}
		&__logo {
			color: $white;
			&--link {
				width: 47px;
				height: 47px;
				display: block;
				transition: width $transition, height $transition;
				@include minw( $grid-breakpoints-sm ) {
					width: 75px;
					height: 75px;
				}
			}
		}
		&__content {
			display: flex;
			align-items: center;
			&--divider {
				width: 1px;
				height: 40px;
				background-color: rgba($white, 0.3);
				margin-left: 15px;
				margin-right: 15px;
				@include minw( $grid-breakpoints-sm ) {
					margin-left: 30px;
					margin-right: 30px;
				}
			}
		}
		.nav {
			margin-right: 15px;
			@include minw( $grid-breakpoints-md ) {
				margin-right: 141px * .8;
			}
			@include minw( $grid-breakpoints-lg ) {
				margin-right: 141px;
			}
		}
		.nav__item:not(:last-child) {
			margin-right: 10px;
			@include minw( $grid-breakpoints-md ) {
				margin-right: 20px;
			}
		}
		.nav,
		.lang,
		.header__content--divider,
		.app-button-group {
			display: none;
			@include minw( $grid-breakpoints-sm ) {
				display: inherit;
			}
		}
		&.mobile_menu {
			background-color: $green !important;
		}
	}

	.header__mobile {
		position: fixed;
		top: 70px;
		left: 0;
		width: 100%;
		height: calc(100% - 70px);
		background-color: $green;
		pointer-events: none;
		border-top: 1px solid rgba($white, .3);
		@include minw( $grid-breakpoints-sm ) {
			display: none !important;
		}
		&__container {
			height: 100%;
			overflow-y: auto;
			pointer-events: auto;
		}
		&__content {
			padding: 62px 20px 55px;
			display: grid;
			grid-template-rows: 1fr auto;
			min-height: 100%;
		}
		.nav {
			display: inline-block;
			margin-right: 0;
			&__item {
				&:not(:last-child) {
					margin-right: 0;
					margin-bottom: 5px;
				}
			}
			&__link {
				font-size: 26px;
				padding-left: 0;
			}
		}
		.lang__mobile {
			display: block;
			margin-top: 25px;
			margin-bottom: 50px;
			&--item {
				&:not(:last-child) {
					margin-right: 55px;
				}
			}
		}
		.app-button-group {
			display: flex;
			justify-content: center;
			.app-btn--small {
				@include maxw( $grid-breakpoints-xs - 1 ) {
					padding: 0;
					flex: 1 0 auto;
				}
			}
		}
		&__open {
			&-enter-active,
			&-leave-active {
				transition: opacity $transition, transform $transition;
			}
			&-enter,
			&-leave-to {
				transform: translate(-20px, 0);
				opacity: 0;
			}
		}
	}
</style>
