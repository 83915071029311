import Vue from "vue";
import {
	ValidationObserver,
	ValidationProvider,
	extend,
	localize
} from 'vee-validate';
import uk from 'vee-validate/dist/locale/uk.json';
import * as rules from 'vee-validate/dist/rules';

localize('uk', uk);
Object.keys(rules).forEach(rule => extend(rule, rules[rule]));

Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);
