<template>
	<v-dialog :value='value' @close-dialog="closeDialog">
		<template v-if='!success'>
			<ValidationObserver v-slot="{ invalid }">
				<form class='feedback-form' @submit.prevent='onSubmit'>

					<div class="row justify-items-center">
						<h3 class='heading-3'>{{ $t('main_content.dialogs.feedback.title') }}</h3>
					</div>

					<div class="row justify-items-center">
						<div class="col-6">
							<ValidationProvider :name="$t('main_content.dialogs.feedback.name_label')" rules="required" v-slot='{errors, classes}'>
								<v-text-field
									:label="$t('main_content.dialogs.feedback.name_label')"
									:placeholder="$t('main_content.dialogs.feedback.name_placeholder')"
									:class='classes'
									name='name'
									type='text'
									v-model='name'
									:errorMessage='errors[0]'
									required
								/>
							</ValidationProvider>
						</div>
						<div class="col-6">
							<ValidationProvider :name="$t('main_content.dialogs.feedback.phone_label')" rules="required|numeric|length:12" v-slot='{errors, classes}'>
								<v-text-field
									:label="$t('main_content.dialogs.feedback.phone_label')"
									placeholder="+380 50 000 00 00"
									:class='classes'
									name='phone'
									type='text'
									v-model='phone'
									:errorMessage='errors[0]'
									required
									:mask='$route.params.lang !== "en" ? "+380 ## ### ## ##" : "+### ## ### ## ##"'
								/>
							</ValidationProvider>
						</div>

						<div class="col-12">
							<ValidationProvider :name="$t('main_content.dialogs.feedback.msg_label')" rules="required" v-slot='{errors, classes}'>
								<v-text-field
									:label="$t('main_content.dialogs.feedback.msg_label')"
									:placeholder="$t('main_content.dialogs.feedback.msg_placeholder')"
									:class='classes'
									name='textarea'
									type='textarea'
									v-model='message'
									:errorMessage='errors[0]'
									required
									:rows='5'
								/>
							</ValidationProvider>
						</div>
					</div>
					<div class="form-btns">
						<v-button block color='green white--text' :disabled="invalid" @click='onSubmit' :loading='loading'>{{$t('main_content.dialogs.feedback.send')}}</v-button>
					</div>
				</form>
			</ValidationObserver>
		</template>

		<div v-else class='app-done-modal'>
			<h3 class="heading-3">{{ $t('main_content.dialogs.feedback.success.title') }}</h3>
			<span>{{ $t('main_content.dialogs.feedback.success.description') }}</span>
			<v-button small class="app-btn green white--text" type="button" @click="closeDialog">{{ $t('main_content.dialogs.feedback.success.button') }}</v-button>
		</div>
	</v-dialog>
</template>

<script>
	import { feedback } from '@/services/request';

	export default {
		data: () => ({
			name: '',
			phone: '',
			message: '',
			loading: false,
			success: false
		}),
		props: ['value'],
		methods: {
			onSubmit() {
				if(this.$store.state.notification.isActive) this.$store.commit('notification/clearMessage');
				this.success = false;
				this.loading = true;

				feedback({name: this.name, phone: this.phone, message: this.message}).then(() => {
					this.success = true;
				}).catch(err => {
					let data = err.response.data;
					this.$store.commit('notification/errorMessage', {errors: data.errors})
				}).finally(() => this.loading = false)
			},
			closeDialog() {
				this.name = '';
				this.phone = '';
				this.message = '';
				this.success = false;
				this.$emit('close-dialog');
			}
		}
	}
</script>

<style lang='scss'>
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.feedback-form {
		.heading-3 {
			text-align: center;
			margin-bottom: 20px;
			@include minw( $grid-breakpoints-xs ) {
				margin-bottom: 40px;
			}
		}
		.form-btns {
			@include minw( $grid-breakpoints-xs ) {
				max-width: 280px;
			}
		}
	}

</style>
