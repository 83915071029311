<template>
	<div class="app-accordion__item">
		<div class="app-accordion__trigger"
			:class="{ 'app-accordion__trigger_active': visible }"
			@click="open"
		>
			<div class="app-accordion__title">
				<div class="row align-items-center">
					<div class="col">
						<h4 class="heading-4">
							<slot name="title" />
						</h4>
					</div>
					<div class="col-auto" v-if='!hideTriggerIcon'>
						<v-icon icon='arrow-down' size='22' class='app-accordion__trigger-icon' />
					</div>
				</div>
			</div>
		</div>

		<transition name="app-accordion"
			@enter="start"
			@after-enter="end"
			@before-leave="start"
			@after-leave="end"
		>
			<div v-show="visible">
				<div class="app-accordion__content">
					<slot name="content" />
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
	export default {
		inject: ["Accordion"],
		data: () => ({
			index: null
		}),
		props: {
			hideTriggerIcon: {
				type: Boolean,
				default: false
			}
		},
		computed: {
			visible() {
				return this.index == this.Accordion.active;
			}
		},
		methods: {
			open() {
				this.Accordion.active = this.visible ? null : this.index;
			},
			start(el) {
				el.style.height = el.scrollHeight + "px";
			},
			end(el) {
				el.style.height = "";
			}
		},
		created() {
			this.index = this.Accordion.count++;
		}
	};
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.app-accordion {
		&__item {
			background-color: $white;
			margin-bottom: 10px;
			border-radius: 5px;
			@include minw( $grid-breakpoints-sm ) {
				border-radius: 15px;
			}
		}
		&__title,
		&__content {
			padding: 20px 25px;
			@include minw( $grid-breakpoints-sm ) {
				padding: 25px * .8 30px * .8;
			}
			@include minw( $grid-breakpoints-md ) {
				padding: 25px * .9 30px * .9;
			}
			@include minw( $grid-breakpoints-lg ) {
				padding: 25px 30px;
			}
		}
		&__content {
			border-top: 1px solid rgba($black, .1);
			&-block {
				p {
					font-size: 14px;
					line-height: 1.8;
					@include minw( $grid-breakpoints-sm ) {
						font-size: 18px * .8;
					}
					@include minw( $grid-breakpoints-md ) {
						font-size: 18px * .9;
					}
					@include minw( $grid-breakpoints-lg ) {
						font-size: 18px;
					}
				}
			}
		}
		.app-icon {
			@include maxw( $grid-breakpoints-sm - 1 ) {
				width: 16px !important;
				height: 16px !important;
			}
		}
		&__trigger {
			cursor: pointer;
			transition: transform $transition;
			&-icon {
				color: $green-persian;
				transition: transform $transition;
			}
			&_active {
				.app-accordion__trigger-icon {
					transform: rotate(-90deg);
				}
			}
		}
		&-enter-active,
		&-leave-active {
			will-change: height, opacity;
			transition: height 0.3s ease, opacity 0.3s ease;
			overflow: hidden;
		}
		&-enter,
		&-leave-to {
			height: 0 !important;
			opacity: 0;
		}
	}
</style>
