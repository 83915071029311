export default [
	{
		path: 'hc',
		meta: { requiresAuth: true },
		component: {
			render: h => h('router-view')
		},
		redirect: { name: 'help' },
		children: [
			{
				path: 'help',
				name: 'help',
				meta: { requiresAuth: true },
				component: () => import('@/views/hc/help')
			},
			{
				path: 'faq',
				meta: { requiresAuth: true },
				component: {
					render: h => h('router-view')
				},
				children: [
					{
						path: '',
						name: 'faq-hc',
						meta: { requiresAuth: true },
						component: () => import('@/views/hc/faq'),
					},
					{
						path: ':categoryId',
						name: 'faq-category',
						meta: { requiresAuth: true },
						component: () => import('@/views/hc/faq-category')
					},
					{
						path: ':categoryId/view/:id',
						name: 'faq-single',
						meta: { requiresAuth: true },
						component: () => import('@/views/hc/faq-single')
					}
				]
			},
			{
				path: 'news',
				meta: { requiresAuth: true },
				component: {
					render: h => h('router-view')
				},
				children: [
					{
						path: '',
						name: 'news',
						meta: { requiresAuth: true },
						component: () => import('@/views/hc/news'),
					},
					{
						path: ':postId',
						name: 'news-single',
						meta: { requiresAuth: true },
						component: () => import('@/views/hc/news-single')
					}
				]
			}
		]
	}
]
