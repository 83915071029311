<template>
	<div class='v-chip' :class='{[color]: color, [`v-chip__size--${size}`]: size, strong: strong, rounded: rounded, disabled: disabled}'>
		<div class="v-chip__icon" v-if='icon'>
			<v-icon :icon='icon' />
		</div>
		<div class="v-chip__content">
			<slot />
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			color: {
				type: String,
				default: 'default'
			},
			disabled: {
				type: Boolean,
				default: false
			},
			icon: {
				type: [String, Boolean],
				default: false
			},
			size: {
				type: String,
				default: 'normal'
			},
			strong: Boolean,
			rounded: Boolean
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.v-chip {
		display: inline-flex;
		align-items: center;
		font-size: 12px;
		font-weight: 700;
		line-height: 15px;
		padding: 5px 10px;
		border-radius: $border-radius;
		@include minw($grid-breakpoints-md) {
			padding: 8px;
			font-size: 13px;
		}
		&__icon {
			margin-right: 6px;
			@include minw($grid-breakpoints-md) {
				margin-right: 4px;
			}
			.app-icon {
				width: 10px !important;
				height: 10px !important;
				@include minw($grid-breakpoints-md) {
					width: 13px !important;
					height: 13px !important;
				}
			}
		}
		&__size {
			&--small {
				font-size: 11px;
				font-weight: 400;
				padding-top: 5px;
				padding-bottom: 5px;
				@include minw($grid-breakpoints-md) {
					font-size: 12px;
				}
			}
		}
		&.strong {
			border-radius: 0;
		}
		&.rounded {
			border-radius: 47px;
		}
		&.cyprus {
			background-color: rgba($cyprus, .1);
			color: rgba($cyprus, .8);
		}
		&.disabled {
			background-color: rgba($black, .03);
			color: rgba($black, .4);
		}
	}
</style>
