<template>
	<div class='a-stepper'>
		<p class='a-stepper-label'>{{title}}:</p>
		<div class='a-stepper-count'>
			<button type='button' class='a-stepper-count-icon' :class='{disabled: count <= minCount}' @click.prevent='$emit("change", count - 1)'>
				<v-icon icon='minus' size='14' />
			</button>
			<span class='a-stepper-count-number'>{{count}}</span>
			<button type='button' class='a-stepper-count-icon' @click.prevent='$emit("change", count + 1)'>
				<v-icon icon='plus' size='14' />
			</button>
		</div>
		<p class='a-stepper-descr' v-if='descr'>{{descr}}</p>
	</div>
</template>

<script>
	export default {
		props: {
			title: {
				type: String
			},
			count: {
				type: Number,
				default: 0
			},
			minCount: {
				type: Number,
				default: 0
			},
			descr: {
				type: String
			}
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-stepper {
		width: 100%;
		&-descr {
			font-size: 12px;
			font-weight: 400;
			color: rgba($black, .4);
			margin-top: 5px;
		}
		&-label {
			font-size: 14px;
			font-weight: 300;
			margin-bottom: 5px;
		}
		&-count {
			display: flex;
			align-items: stretch;
			border: 1px solid rgba($black, 0.05);
			border-radius: 5px;
			overflow: hidden;
			&-icon {
				width: 38px;
				height: 38px;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-shrink: 0;
				background-color: rgba($green-persian, .05);
				transition: background-color $transition;
				cursor: pointer;
				.app-icon {
					opacity: .4;
				}
				&:hover {
					background-color: rgba($green-persian, .25);
				}
				&.disabled {
					pointer-events: none;
					background-color: transparent;
					.app-icon {
						opacity: .1;
					}
				}
			}
			&-number {
				padding: 0 12px;
				font-size: 20px;
				flex: 1 1 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				border-right: 1px solid rgba($black, .1);
				border-left: 1px solid rgba($black, .1);
			}
		}
	}
</style>
