var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"value":_vm.value},on:{"close-dialog":_vm.closeDialog}},[(!_vm.success)?[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{staticClass:"feedback-form",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"row justify-items-center"},[_c('h3',{staticClass:"heading-3"},[_vm._v(_vm._s(_vm.$t('main_content.dialogs.feedback.title')))])]),_c('div',{staticClass:"row justify-items-center"},[_c('div',{staticClass:"col-6"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('main_content.dialogs.feedback.name_label'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('v-text-field',{class:classes,attrs:{"label":_vm.$t('main_content.dialogs.feedback.name_label'),"placeholder":_vm.$t('main_content.dialogs.feedback.name_placeholder'),"name":"name","type":"text","errorMessage":errors[0],"required":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}],null,true)})],1),_c('div',{staticClass:"col-6"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('main_content.dialogs.feedback.phone_label'),"rules":"required|numeric|length:12"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('v-text-field',{class:classes,attrs:{"label":_vm.$t('main_content.dialogs.feedback.phone_label'),"placeholder":"+380 50 000 00 00","name":"phone","type":"text","errorMessage":errors[0],"required":"","mask":_vm.$route.params.lang !== "en" ? "+380 ## ### ## ##" : "+### ## ### ## ##"},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})]}}],null,true)})],1),_c('div',{staticClass:"col-12"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('main_content.dialogs.feedback.msg_label'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('v-text-field',{class:classes,attrs:{"label":_vm.$t('main_content.dialogs.feedback.msg_label'),"placeholder":_vm.$t('main_content.dialogs.feedback.msg_placeholder'),"name":"textarea","type":"textarea","errorMessage":errors[0],"required":"","rows":5},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}})]}}],null,true)})],1)]),_c('div',{staticClass:"form-btns"},[_c('v-button',{attrs:{"block":"","color":"green white--text","disabled":invalid,"loading":_vm.loading},on:{"click":_vm.onSubmit}},[_vm._v(_vm._s(_vm.$t('main_content.dialogs.feedback.send')))])],1)])]}}],null,false,58032486)})]:_c('div',{staticClass:"app-done-modal"},[_c('h3',{staticClass:"heading-3"},[_vm._v(_vm._s(_vm.$t('main_content.dialogs.feedback.success.title')))]),_c('span',[_vm._v(_vm._s(_vm.$t('main_content.dialogs.feedback.success.description')))]),_c('v-button',{staticClass:"app-btn green white--text",attrs:{"small":"","type":"button"},on:{"click":_vm.closeDialog}},[_vm._v(_vm._s(_vm.$t('main_content.dialogs.feedback.success.button')))])],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }