<template>
	<date-picker
		v-model="date"
		@change='$emit("change", $event)'
		:placeholder='placeholder ? placeholder : $t("account.form.placeholder.choose_a_date")'
		:range='range'
		format="DD/MM/YYYY"
		:lang="$route.params.lang ? $route.params.lang : 'uk'"
		:disabled-date="date => date <= new Date().setHours(0, 0, 0, 0)"
		range-separator=' — '
		:append-to-body='false'
		class='a-booking-form-datepicker'
		:class='{"mx-datepicker-right": right, "invalid": errors}'
		:editable='false'
		value-type='timestamp'
		:partial-update='true'
		:open.sync="open"
	>
		<template v-slot:header>
			<div class="row align-items-center">
				<div class="col-auto">
					<div class='mx-datepicker-header-close' @click="open = false">
						<v-icon icon="cross" size="20"></v-icon>
					</div>
				</div>
			</div>
		</template>
	</date-picker>
</template>

<script>
	import DatePicker from 'vue2-datepicker'

	const { updateCalendars } = DatePicker.CalendarRange.methods

	DatePicker.CalendarRange.methods.updateCalendars = function (...args) {
		updateCalendars.apply(this, args);

		if(args[1] !== undefined) {
			this.calendars = this.calendars.map((v, i) => {
				if(args[1] === 1) {
					const next = new Date(this.calendars[0]);
					next.setMonth(i === 0 ? next.getMonth() : next.getMonth() + 1);
					return next;
				} else if (args[1] === 0) {
					const next = new Date(this.calendars[1]);
					next.setMonth(i === 1 ? next.getMonth() : next.getMonth() - 1);
					return next;
				}
			});
		}
	};

	export default {
		data: () => ({
			open: false,
			date: null
		}),
		props: {
			value: {
				type: [String, Number, Array]
			},
			range: {
				type: Boolean,
				default: false
			},
			right: {
				type: Boolean,
				default: false
			},
			placeholder: {
				type: String
			},
			errors: {
				type: [Boolean, Array],
				default: false
			}
		},
		created() {
			this.date = this.value;
		},
		watch: {
			value() {
				this.date = this.value;
			}
		}
	}
</script>
