<template>
	<header class="a-header" data-scroll-lock-fill-gap :class="{'topFixed': topFixed, 'mobile_menu': mobile_menu, 'isStatic': !isStatic}">
		<div class="app-container app-container-fluid a-header__container">
			<div class="row align-items-center flex-nowrap">
				<div class="col-auto a-header-col-auto pr-0">
					<div class="a-header__logo" >
						<router-link
							:to="$i18nRoute({ name: 'index' })"
							class="a-header__logo--link"
							v-html="require('!html-loader!@/assets/logo-color.svg')"
						/>
					</div>
				</div>
				<div class="col">
					<div class="a-header__content">
						<a-nav :items="items" />
						<a-header-profile @logoutProfile='logout = true' :logout='logout' />
						<div class="a-header__bag">
							<router-link class="a-header__bag--button" :to="$i18nRoute({ name: 'trips' })">
								<v-icon icon='bag' size='26'></v-icon>
								<span class='a-header__bag--count' v-if='suitcase_total > 0'>{{suitcase_total}}</span>
							</router-link>
						</div>
						<div class="navigation navigation--green" @click='mobile_menu = !mobile_menu'>
							<span class="navigation-button" :class="{'open': mobile_menu}"></span>
						</div>
					</div>
				</div>
			</div>
		</div>

		<transition name="a-header__mobile__open">
			<div class='a-header__mobile' v-if='mobile_menu'>
				<div class='a-header__mobile__container'>
					<div class='a-header__mobile__content'>
						<a-nav :items="items" />
						<div class="divider"></div>
						<ul class="a-nav">
							<li class="a-nav__item" @click="logout = true">
								<div class='a-nav__link'>{{$t('account.exit')}}</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</transition>

		<a-dialog-delete
			v-model='logout'
			:title='$t("account.dialogs.delete.title")'
			@close-dialog="logout = false"
			:nameButton='$t("account.dialogs.buttons.exit")'
			@action-dialog='logoutProfile'
			color='green-persian'
		/>

	</header>
</template>

<script>
	import ANav from "@/components/account/ANav.vue";
	import AHeaderProfile from "@/components/account/AHeaderProfile.vue";
	import ADialogDelete from '@/components/account/dialogs/delete';
	import { logout } from '@/services/request';
	import { disablePageScroll, enablePageScroll } from 'scroll-lock';

	export default {
		data: () => ({
			container: null,
			topFixed: false,
			mobile_menu: false,
			logout: false
		}),
		computed: {
			items() {
				return [
					{ name: this.$t('routes.home'), link: "home" },
					{ name: this.$t('routes.my-orders'), link: "my-orders" },
					{ name: this.$t('routes.tourists'), link: "tourists" },
					// { name: this.$t('routes.reports'), link: "" },
					{ name: this.$t('routes.favourite'), link: "favourite" },
					{ name: this.$t('routes.settings'), link: "settings" },
					{ name: this.$t('routes.help'), link: "help" },
					{ name: this.$t('routes.faq'), link: "faq-hc" },
					{ name: this.$t('routes.news'), link: "news" }
				]
			},
			isStatic() {
				return !this.$route.meta.isStatic;
			},
			suitcase_total() {
				return this.$store.state.suitcase_total;
			}
		},
		components: {
			ANav,
			AHeaderProfile,
			ADialogDelete
		},
		created() {
			this.container = document.documentElement;
			this.topFixed = this.container.scrollTop >= 1

			window.addEventListener("scroll", () => {
				let self = this;
				self.topFixed = self.container.scrollTop >= 1;
			}, false );
		},
		methods: {
			logoutProfile() {
				logout().then(() => this.$store.dispatch('auth/logout'))
			}
		},
		watch: {
			"$route.name"() {
				this.mobile_menu = false;
			},
			mobile_menu() {
				setTimeout(() => {
					const hideEl = document.querySelector('.a-header__mobile__container')
					this.mobile_menu ? disablePageScroll(hideEl) : enablePageScroll(hideEl)
				}, 150)
			}
		},
		beforeDestroy() {
			enablePageScroll()
		}
	};
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-header {
		background-color: $white;
		position: fixed;
		top: 0;
		right: 0;
		left: 0;
		z-index: 10;
		transition: background-color $transition;
		border-bottom: 1px solid rgba($black, .1);
		&:not(.isStatic) {
			& + .main {
				padding-top: 70px;
				@include minw( $grid-breakpoints-sm ) {
					padding-top: 105px * .8;
				}
				@include minw( $grid-breakpoints-md ) {
					padding-top: 105px;
				}
			}
		}
		&.topFixed {
			&:not(.isStatic) {
				@include minw( $grid-breakpoints-sm ) {
					.a-header {
						&__container {
							height: 77px * .8;
							@include minw( $grid-breakpoints-md ) {
								height: 77px;
							}
						}
						&__logo--link {
							width: 63px * .8;
							height: 63px * .8;
							@include minw( $grid-breakpoints-md ) {
								width: 63px;
								height: 63px;
							}
						}
					}
				}
			}
		}
		&.isStatic {
			position: relative !important;
		}
		&__container {
			height: 70px;
			display: grid;
			@include minw( $grid-breakpoints-sm ) {
				height: 105px * .8;
			}
			@include minw( $grid-breakpoints-md ) {
				height: 105px;
			}
		}
		&__logo {
			color: $white;
			&--link {
				width: 47px;
				height: 47px;
				display: block;
				transition: width $transition, height $transition;
				@include minw( $grid-breakpoints-sm ) {
					width: 75px * .8;
					height: 75px * .8;
				}
				@include minw( $grid-breakpoints-md ) {
					width: 75px;
					height: 75px;
				}
			}
		}
		&__content {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			&--divider {
				width: 1px;
				height: 40px;
				background-color: rgba($white, 0.3);
				margin-left: 15px;
				margin-right: 15px;
				@include minw( $grid-breakpoints-sm ) {
					margin-left: 30px * .8;
					margin-right: 30px * .8;
				}
				@include minw( $grid-breakpoints-md ) {
					margin-left: 30px;
					margin-right: 30px;
				}
			}
		}
		&__bag {
			margin-right: 12px * .8;
			color: $green-persian;
			@include minw( $grid-breakpoints-md ) {
				margin-right: 12px;
				margin-left: 12px;
			}
			&--button {
				padding: 12px;
				cursor: pointer;
			}
			&--count {
				position: absolute;
				bottom: 3px;
				right: 3px;
				min-width: 23px;
				height: 23px;
				font-size: 12px;
				font-weight: 700;
				color: $white;
				background-color: $cyprus;
				border-radius: 12px;
				padding: 5px 8px;
			}
		}
		.a-nav,
		.a-header-profile,
		.a-header__bag {
			display: none;
			@include minw( $grid-breakpoints-sm ) {
				display: inherit;
			}
		}
		&.mobile_menu {
			background-color: $white !important;
			.a-header-col-auto,
			.a-header-profile__body {
				display: none;
			}
			.a-header-profile,
			.a-header__bag {
				display: inherit;
			}
			.a-header-profile {
				margin-right: auto;
			}
		}
	}

	.a-header__mobile {
		position: fixed;
		top: 71px;
		left: 0;
		width: 100%;
		height: calc(100% - 71px);
		background-color: $white;
		pointer-events: none;
		@include minw( $grid-breakpoints-sm ) {
			display: none !important;
		}
		&__container {
			height: 100%;
			overflow-y: auto;
			pointer-events: auto;
		}
		&__content {
			padding: 24px 20px;
			min-height: 100%;
		}
		.a-nav {
			display: inline-block;
			margin-right: 0;
			&__item {
				display: flex;
				&:not(:last-child) {
					margin-right: 0;
					margin-bottom: 18px;
				}
			}
			&__link {
				font-size: 15px;
				padding-left: 0;
			}
		}
		.divider {
			margin: 24px -20px;
		}
		&__open {
			&-enter-active,
			&-leave-active {
				transition: opacity $transition, transform $transition;
			}
			&-enter,
			&-leave-to {
				transform: translate(-20px, 0);
				opacity: 0;
			}
		}
	}
</style>
