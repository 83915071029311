<template>
	<div class="lang__container">
		<div
			class="lang"
			:class="{ hover: visible, top: top }"
			@mouseover="visible = true, adjustPosition()"
			@mouseleave="visible = false"
		>
			<div class="lang__current white--text">
				<v-icon class="lang__current--icon language" icon="language" size="15" />
				{{ locales.find(el => el.code == currentLanguage).name }}
				<v-icon
					class="lang__current--icon expand--more"
					icon="expandMore"
					size="10"
				/>
			</div>
			<div class="lang__list">
				<div
					class="lang__list--item"
					v-for="(item, i) in locales.filter(el => el.code != currentLanguage)"
					:key="i"
					@click.stop="changeLanguage(item.code), (visible = false)"
				>
					{{ item.name }}
				</div>
			</div>
		</div>

		<div class="lang__mobile">
			<div class="lang__mobile--list">
				<div
					class="lang__mobile--item"
					:class='{"active": item.code == currentLanguage}'
					v-for="(item, i) in locales"
					:key="i"
					@click="changeLanguage(item.code), (visible = false)"
				>
					{{ item.name }}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { Trans } from "@/plugins/Translation";

	export default {
		data: () => ({
			locales: [
				{ code: "uk", name: "Укр" },
				{ code: "ru", name: "Рус" },
				{ code: "en", name: "Eng" }
			],
			visible: false,
			top: false
		}),
		computed: {
			currentLanguage() {
				return Trans.currentLanguage;
			}
		},
		methods: {
			changeLanguage(lang) {
				return Trans.changeLanguage(lang).then(() => {
					document.title = this.$t(`routes.${this.$route.name}`) + ' - ' + process.env.VUE_APP_TITLE;
					this.$router.push({params: {lang: Trans.defaultLanguage === lang ? undefined : lang}, name: this.$route.name})
				});
			},
			adjustPosition() {
				if (typeof window === 'undefined') return

				const spaceAbove = this.$el.getBoundingClientRect().top
				const spaceBelow = window.innerHeight - this.$el.getBoundingClientRect().bottom
				const hasEnoughSpaceBelow = spaceBelow > document.querySelector('.lang__list').getBoundingClientRect().height

				this.top = !(hasEnoughSpaceBelow || spaceBelow > spaceAbove)
			}
		}
	};
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.lang {
		position: relative;
		z-index: 10;
		font-size: 14px;
		font-weight: 400;
		text-transform: uppercase;
		height: 40px;
		min-width: 75px;
		display: inline-flex;
		align-items: center;
		&.hover {
			& > .lang__list {
				visibility: visible;
				opacity: 1;
				transform: translateY(0);
			}
		}
		&.top {
			.lang__list {
				top: auto;
				bottom: 100%;
				border-radius: $border-radius * 2 0 $border-radius * 2 0;
			}
		}
		&__container {
			display: grid;
			@include minw( $grid-breakpoints-sm ) {
				display: inline-grid;
			}
		}
		&__current {
			display: flex;
			align-items: center;
			cursor: default;
			&--icon {
				&.language {
					margin-right: 10px;
				}
				&.expand--more {
					margin-left: 5px;
				}
			}
		}
		&__list {
			position: absolute;
			top: 100%;
			left: 0;
			right: 0;
			background-color: $white;
			border-radius: 0 $border-radius * 2 0 $border-radius * 2;
			box-shadow: 0px 4px 30px rgba(86, 86, 86, 0.2);
			transition: transform $transition, opacity $transition, visibility $transition;
			overflow: hidden;
			opacity: 0;
			visibility: hidden;
			transform: translateY(-10px);
			will-change: transform;
			&--item {
				text-align: center;
				padding: 15px;
				cursor: pointer;
				transition: background-color $transition;
				&:hover {
					background-color: $azure;
				}
			}
		}
		&__mobile {
			display: none;
			&--list {
				display: flex;
				justify-content: center;
			}
			&--item {
				color: $white;
				&:not(:last-child) {
					margin-right: 12px;
				}
				&.active {
					font-weight: 700;
				}
			}
		}
	}
</style>
