import axios from 'axios';
import router from "@/router";
import { Trans } from "@/plugins/Translation";

const state = {
	user: null
}

const mutations = {
	user(state, payload) {
		state.user = payload
		if(Trans.currentLanguage !== state.user.language){
			Trans.changeLanguage(state.user.language).then(() => {
				router.push({params: {lang: Trans.defaultLanguage === state.user.language ? undefined : state.user.language}, name: router.app.$route.name, query: router.app.$route.query})
			})
		}

	}
}

const actions = {
	login({ commit }, data) {
		window.localStorage.setItem('token_type', data.token_type);
		window.localStorage.setItem('access_token', data.access_token);
		axios.defaults.headers.common['Authorization'] = data.token_type + ' ' + data.access_token
		commit('user', data.user);
	},
	logout({ state, commit }) {
		window.localStorage.removeItem('token_type');
		window.localStorage.removeItem('access_token');
		window.localStorage.removeItem('local_search_data');
		delete axios.defaults.headers.common['Authorization'];
		router.push({ name: "index", params: {lang: Trans.defaultLanguage === state.user.language ? undefined : state.user.language}});
		commit('user', null);
	}
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
	getters
}
