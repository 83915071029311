<template>
	<footer class="footer">
		<div class="footer__container">
			<div class="app-container app-container-fluid">
				<div class="row align-items-center">
					<div class="col-auto" :class='{"tablet-hide": isIndexPage}'>
						<app-nav :items="items" />
					</div>
					<div class="col">
						<div class="row" :class='{"footer-reverse": isIndexPage}'>
							<div class="col">
								<div class='tablet-show'>
									<app-locales-select :class='{"tablet-hide": isIndexPage}' />
								</div>
								<div class='footer-cookie'>
									<router-link :to='$i18nRoute({name: "cookie"})' class='footer-cookie-link'>{{$t('main.footer.cookie_usage_policy')}}</router-link>
									<router-link :to='$i18nRoute({name: "privacy-policy"})' class='footer-cookie-link'>{{$t('main.footer.privacy_policy')}}</router-link>
								</div>
							</div>
							<div class="col-auto footer__action" :class='{"tablet-hide": !isIndexPage}'>
								<v-button outline color="white" @click='dialogFeedback = true'>
									{{$t('main.buttons.contacts_us')}}
								</v-button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<app-dialog-feedback :value='dialogFeedback' @close-dialog='dialogFeedback = false' />

	</footer>
</template>

<script>
	import AppNav from "@/components/main/AppNav.vue";
	import AppDialogFeedback from "@/components/main/AppDialogFeedback.vue";
	import AppLocalesSelect from '@/components/main/AppLocalesSelect.vue';

	export default {
		data: () => ({
			dialogFeedback: false
		}),
		props: ["isIndexPage"],
		computed: {
			items() {
				return [
					{ name: this.$t('routes.index'), link: "index" },
					{ name: this.$t('routes.about'), link: "about" },
					{ name: this.$t('routes.contacts'), link: "contacts" },
					{ name: this.$t('routes.sign-in-agent'), link: "sign-in" },
					{ name: this.$t('routes.sign-up-agent'), link: "sign-up" }
				]
			}
		},
		components: {
			AppNav,
			AppDialogFeedback,
			AppLocalesSelect
		}
	};
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.footer {
		position: relative;
		background-color: $green;
		@include maxw( $grid-breakpoints-sm - 1 ) {
			.row {
				flex-direction: column;
			}
		}
		&:before {
			content: "";
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background-color: rgba($silver, 0.3);
			mask-image: url("../../assets/images/background-lines.svg");
		}
		&__container {
			width: 100%;
			position: relative;
			z-index: 1;
			display: grid;
			padding: 0 20px;
			@include minw( $grid-breakpoints-sm ) {
				padding: 0 120px * .6;
			}
			@include minw( $grid-breakpoints-md ) {
				padding: 0 120px * .8;
			}
			@include minw( $grid-breakpoints-lg ) {
				padding: 0 120px;
			}
		}
		.nav {
			flex-direction: column;
			align-items: flex-start;
			padding-top: 30px;
			padding-bottom: 30px;
			&__item {
				&:not(:last-child) {
					margin-right: 0;
					margin-bottom: 15px;
				}
			}
			&__link {
				font-size: 16px;
				font-weight: 400;
				padding: 0;
				@include minw( $grid-breakpoints-sm ) {
					&:hover {
						background-color: transparent;
						color: rgba($white, .7);
						transition: color $transition;
					}
				}
			}
			@include maxw( $grid-breakpoints-sm - 1 ) {
				align-items: center;
				padding-bottom: 49px;
				&__link {
					font-size: 18px;
				}
			}
		}
		&__action {
			margin-left: auto;
			@include minw( $grid-breakpoints-sm ) {
				.app-btn {
					height: 52px;
				}
			}
			@include maxw( $grid-breakpoints-sm - 1 ) {
				margin: 30px 0;
			}
		}
		&-cookie {
			margin-bottom: 66px;
			@include minw( $grid-breakpoints-sm ) {
				margin-bottom: 0;
			}
			&-link {
				display: block;
				font-size: 14px;
				font-weight: 400;
				color: rgba($white, .5);
				text-align: center;
				transition: color $transition;
				&:not(:last-child) {
					margin-bottom: 12px;
				}
				@include minw( $grid-breakpoints-sm ) {
					font-size: 16px;
				}
				&:hover {
					color: $white;
				}
			}
		}
		&-reverse {
			@include maxw( $grid-breakpoints-sm - 1 ) {
				flex-direction: column-reverse !important;
			}
		}
		.lang {
			display: none;
			@include minw( $grid-breakpoints-sm ) {
				display: inherit;
			}
		}
		.lang__mobile {
			display: block;
			margin-bottom: 88px;
		}
	}
</style>
