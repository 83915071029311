<template>
	<div class="a-dropdown" :class="{ open: open, invalid: invalid }" v-click-outside="closeSelect">
		<slot name='label' />
		<div class="a-dropdown__control">
			<div class="a-dropdown__slot" @click="open = !open">
				<slot name='field' :openSelect='openSelect' :closeSelect="closeSelect" />
			</div>
		</div>
		<div class="a-dropdown__body" data-scroll-lock-scrollable :class='{[classDropdown]: classDropdown, "right": dropdownRight}' v-if='open'>
			<slot name='dropdown' :closeSelect="closeSelect" />
		</div>
	</div>
</template>

<script>
	export default {
		data: () => ({
			open: false
		}),
		props: ["class-dropdown", "invalid", "dropdown-right"],
		methods: {
			openSelect() {
				this.open = true
			},
			closeSelect() {
				this.open = false
			}
		}
	};
</script>

<style lang="scss">
	@import "@/styles/global/variables.scss";
	@import "@/styles/global/mixins";

	.a-dropdown {
		font-size: 16px;
		font-weight: 400;
		max-width: 100%;
		cursor: default;
		&__control {
			cursor: pointer;
		}
		&.open,
		&.invalid {
			.a-dropdown__slot:before {
				height: 3px;
			}
			.a-dropdown__append-inner {
				transform: rotate(-90deg);
			}
		}
		&.invalid {
			.a-dropdown__slot:before {
				background-color: $red;
			}
		}
		&__slot {
			padding: 0 15px;
			min-height: 62px;
			border: 1px solid rgba($black, 0.1);
			border-radius: $border-radius;
			position: relative;
			display: flex;
			align-items: center;
			transition: border-color $transition;
			&:hover {
				border-color: rgba($black, 0.3);
			}
			&:before {
				content: "";
				position: absolute;
				bottom: 0;
				right: 0;
				left: 0;
				height: 0;
				background-color: $green;
				border-radius: 0 0 30px 30px;
				transition: height $transition;
			}
		}
		&__body {
			z-index: 8;
			position: absolute;
			top: 100%;
			left: 0;
			margin-top: 2px;
			background-color: $white;
			box-shadow: 0px 4px 30px rgba(86, 86, 86, 0.2);
			border-radius: 0 0 10px 10px;
			@include maxw( $grid-breakpoints-xs - 1 ) {
				width: 100%;
			}
			&.right {
				left: auto;
				right: 0;
			}
		}
	}
</style>
