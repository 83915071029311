<template>
	<div class='a-user' :class='{"a-user--border": isBorder, "a-user--large": isLarge}'>
		<div class='a-user--avatar' :style='user.avatar_color ? `background-color: ${user.avatar_color}` : ""'>
			<img class='a-user--avatar--image' :src="user.avatar" v-if='user.avatar'>
			<p class='a-user--avatar--abbr' v-else>{{abbr}}</p>
		</div>
		<div class='a-user--content'>
			<p class='a-user--title'>{{title}}</p>
			<p class='a-user--sub-title' v-if='subTitle'>{{subTitle}}</p>
		</div>
		<slot name='action' />
	</div>
</template>

<script>
	export default {
		props: {
			user: {
				type: Object
			},
			index: {
				type: Number,
				default: 0
			},
			subTitle: {
				type: String
			},
			isBorder: {
				type: Boolean,
				default: false
			},
			isLarge: {
				type: Boolean,
				default: false
			}
		},
		computed: {
			abbr() {
				let name = this.user.firstName ?? this.user.name;
				let surname = this.user.lastName ?? this.user.surname;

				return name
					? (surname
						? `${name.slice(0, 1).toUpperCase()}${surname.slice(0, 1).toUpperCase()}`
						: name.slice(0, 1).toUpperCase())
					: `${this.$t('account.t')}`;
			},
			title() {
				let name = this.user.firstName ?? this.user.name;
				let surname = this.user.lastName ?? this.user.surname;

				return name
					? (surname
						? `${name} ${surname}`
						: name)
					: `${this.$t('account.tourist')} ${this.index + 1}`;
			}
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-user {
		display: flex;
		align-items: center;
		color: $black;
		&--border {
			padding: 4px;
			padding-right: 12px;
			border: 1px solid rgba($black, .1);
			border-radius: 62px;
		}
		&--large {
			.a-user {
				&--title {
					font-size: 20px;
				}
				&--sub-title {
					font-size: 14px;
				}
			}
		}
		&--avatar {
			width: 40px;
			height: 40px;
			margin-right: 10px;
			background-color: #90CAC3;
			border-radius: 50%;
			overflow: hidden;
			flex-shrink: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			&--image {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
			&--abbr {
				color: $white;
				font-size: 20px;
				font-weight: 400;
				text-transform: uppercase;
			}
		}
		&--title {
			font-size: 16px;
			font-weight: 400;
		}
		&--sub-title {
			font-size: 12px;
			font-weight: 400;
			color: rgba($black, .5);
		}
		&--action {
			padding-left: 16px;
			&--btn {
				cursor: pointer;
				transition: color $transition;
				&:hover {
					color: $green-persian;
				}
			}
		}
	}
</style>
