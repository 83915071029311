export default [
	{
		path: 'sign-in',
		name: 'sign-in',
		meta: { guest: true },
		component: () => import('@/views/auth/sign-in')
	},
	{
		path: 'sign-up',
		name: 'sign-up',
		meta: { guest: true },
		component: () => import('@/views/auth/sign-up')
	},
	{
		path: 'password-recovery',
		name: 'password-recovery',
		meta: { guest: true },
		component: () => import('@/views/auth/password-recovery')
	}
]
